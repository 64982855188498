import React, { createContext, useContext, useMemo } from 'react';
import { PrefixedStorage, createStorage } from '@telescope/cassini-utilities';

const StorageContext = createContext<PrefixedStorage | undefined>(undefined);

export const StorageProvider = ({ prefix, children }: { prefix: string; children: React.ReactElement }) => {
  const storage = useMemo(() => {
    return createStorage(prefix);
  }, [prefix]);

  return <StorageContext.Provider value={storage}>{children}</StorageContext.Provider>;
};

export function useStorage() {
  const context = useContext(StorageContext);

  if (context === undefined) {
    throw new Error('useStorage must be used within a StorageContext');
  }

  return context;
}
